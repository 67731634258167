import React, { Fragment } from 'react';
import Button from '@material-ui/core/Button';
import { useNavigate, useLocation } from '@reach/router';

import { Spacer } from '../constants/layout';


export const NavButton = ({ label, to }) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Button
      onClick={() => navigate(to)}
      variant={to === location.pathname ? 'outlined' : 'contained'}
      color="primary"
    >
      {label}
    </Button>
  );
};

export const ExternalButton = ({
  label, icon, to, rightIcon,
}) => (
  <Fragment>
    <Button
      variant="contained"
      href={to}
      target="_empty"
      rel="noopener noreferrer"
      color="primary"
    >
      {icon}
      <Spacer w="10" />
      {label}
      <Spacer w="10" />
      {rightIcon}
    </Button>
    <Spacer h="10" />
  </Fragment>
);

