import { createMuiTheme } from '@material-ui/core/styles';
import { css } from '@emotion/core';


export const colour = {
  // https://colorhunt.co/palette/22283130475ec1a57bececec
  primary: 'hsl(216,100%,14%)',
  secondary: '#4b3823',
  highlight: 'hsl(195, 30%, 48%)',
  background: '#30475E',
  text: 'hsl(37,80%,90%)',
  // Icon colours
  assassin: '#d20000',
  canister: '#B4ECB4',
  itch: '#DDD',
  traitBody: '#624a2e',
  traitPips: '#f2f6fc',
  shadowdarkBody: '#FFF',
  shadowdarkPips: '#111',
};

export const fontSize = {
  pageTitle: '32px',
  header: '20px',
  body: '16px',
};

const borderRadius = 8;

export const roundCorner = {
  all: `border-radius: ${borderRadius}px`,
  right: `border-radius: 0 ${borderRadius}px ${borderRadius}px 0`,
  left: `border-radius: ${borderRadius}px 0 0 ${borderRadius}px`,
  top: `border-radius: ${borderRadius}px ${borderRadius}px 0 0`,
  bottom: `border-radius: 0 0 ${borderRadius}px ${borderRadius}px`,
};

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: colour.primary,
      contrastText: colour.text,
    },
    secondary: {
      main: colour.secondary,
      contrastText: colour.text,
    },
  },
  typography: {
    fontFamily: [
      'Nova Mono',
      'monospace',
    ].join(','),
    fontSize: 16,
  },
});

export const globalStyles = css`
  body {
    background-color: ${colour.highlight};
  }
`;
