import React from 'react';
import { Router as ReachRouter } from '@reach/router';
import styled from '@emotion/styled';

import { Welcome } from '../components/Welcome';
import { MissingPage } from '../components/MissingPage';
import { colour, roundCorner } from '../constants/style';


const PageWrapper = styled.div`
  padding: 5px 10px;
  max-width: 500px;
  margin: auto;
  margin-top: 4px;
  background-color: ${colour.background};
  ${roundCorner.all};
  border: solid ${colour.secondary} 8px;

  @media (min-width: 920px) {
    max-width: 95%;
  }

  @media (min-width: 1600px) {
    max-width: 1520px;
  }
`;

export const Router = () => (
  <PageWrapper>
    <ReachRouter>
      <Welcome path="/" />
      <MissingPage default />
    </ReachRouter>
  </PageWrapper>
);
