import React from 'react';
import styled from '@emotion/styled';

import { HeaderText } from './text';
import { colour } from '../constants/style';
import { ExternalButton } from './Button';
import { ReactComponent as Canister } from '../assets/icons/storage-canister.svg';
import { ReactComponent as Assassin } from '../assets/icons/assassin.svg';
import { ReactComponent as WorldGen } from '../assets/icons/world-generator.svg';
import { ReactComponent as Itch } from '../assets/icons/itchio-textless-black.svg';
import { ReactComponent as Die } from '../assets/icons/die.svg';
import { ReactComponent as MTG } from '../assets/icons/mtg-booster.svg';
import { ReactComponent as GreySeaWeapons } from '../assets/icons/grey-sea-weapons.svg';
import { ReactComponent as PatternTimer } from '../assets/icons/pattern-timer.svg';
import { ReactComponent as Norvelios } from '../assets/icons/norvelios.svg';
import { ReactComponent as FrontierswomansLeague } from '../assets/icons/frontierswomans-league.svg';
import RainbowRipples from '../assets/icons/rainbow-ripples.png';
import CityCaretaker from '../assets/icons/city-caretaker.png';
import CircleOfTheGarden from '../assets/icons/circle-of-the-garden.png';
import FlightApp from '../assets/icons/flightapp.png';


const WelcomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;

  @media (min-width: 1540px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
  }
`;

const ColGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 920px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
  }
`;

const ProjectGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 10px;
  align-items: center;
`;

const DieIconCharTraits = styled(Die)`
  .die {
    fill: ${colour.traitBody};
  }
  .pip {
    fill: ${colour.traitPips};
  }
`;

const DieIconShadowdark = styled(Die)`
  .die {
    fill: ${colour.shadowdarkBody};
  }
  .pip {
    fill: ${colour.shadowdarkPips};
  }
`;

const generalGameResources = [
  {
    to: 'https://pattern-timer.tarek-embree.com/',
    label: 'Pattern Timer',
    icon: (
      <PatternTimer
        width={50}
      />
    ),
  },
  {
    to: 'https://world-generator.tarek-embree.com/',
    label: 'Random World Generator',
    icon: (
      <WorldGen
        width={50}
      />
    ),
  },
  {
    to: 'https://mtg.tarek-embree.com/',
    label: 'Random Magic Booster Pack',
    icon: (
      <MTG
        height={50}
        stroke={colour.canister}
      />
    ),
  },
  {
    to: 'https://traits.tarek-embree.com/',
    label: 'Random Character Traits',
    icon: (
      <DieIconCharTraits
        width={50}
      />
    ),
  },
];

const specificGameResources = [
  {
    to: 'https://assassin-tools.tarek-embree.com/',
    label: 'Assassin\'s Creed Dice Roller',
    icon: (
      <Assassin
        width={50}
        fill={colour.assassin}
      />
    ),
  },
  {
    to: 'https://commodity.tarek-embree.com/',
    label: 'Frontier Space Commodity Converter',
    icon: (
      <Canister
        height={60}
        stroke={colour.canister}
      />
    ),
  },
  {
    to: 'https://boards.tarek-embree.com/',
    label: 'Frontier Space Community Boards',
    icon: (
      <FrontierswomansLeague
        width={50}
      />
    ),
    rightIcon: (
      <Norvelios
        width={50}
      />
    ),
  },
  {
    to: 'https://west.tarek-embree.com/',
    label: 'Grey Sea Weapon Customiser',
    icon: (
      <GreySeaWeapons
        width={50}
      />
    ),
  },
  {
    to: 'https://shadowdark.tarek-embree.com/',
    label: 'Shadowdark Randomisers',
    icon: (
      <DieIconShadowdark
        width={50}
      />
    ),
  },
];

const videoGames = [
  {
    to: 'https://meeinotl.itch.io/rainbow-ripples',
    label: 'Rainbow Ripples',
    icon: (
      <img
        height={50}
        src={RainbowRipples}
        alt="Spots of rainbow colour"
      />
    ),
  },
  {
    to: 'https://meeinotl.itch.io/city-caretaker',
    label: 'City Caretaker',
    icon: (
      <img
        height={50}
        src={CityCaretaker}
        alt="Blue background with symbols representing water, transport, cleanliness, and power with a wrench in the middle"
      />
    ),
  },
  {
    to: 'https://meeinotl.itch.io/circle-of-the-garden',
    label: 'Circle of the Garden',
    icon: (
      <img
        height={50}
        src={CircleOfTheGarden}
        alt="A witch in gardening clothes holding a trowel"
      />
    ),
  },
  {
    to: 'https://meeinotl.itch.io/',
    label: 'Other Games at Itch.io',
    icon: (
      <Itch
        width={50}
        fill={colour.itch}
      />
    ),
  },
];

const professionalProjects = [
  {
    to: 'https://flightapp.io/',
    label: '',
    icon: (
      <img
        height={50}
        src={FlightApp}
        alt="FlightApp!"
      />
    ),
  },
];

const ExternalLinks = ({ links }) => (
  links.map(link => (
    <ExternalButton
      to={link.to}
      label={link.label}
      icon={link.icon}
      key={link.to}
      rightIcon={link.rightIcon}
    />
  )));

export const Welcome = () => (
  <WelcomeWrapper>
    <ColGroup>
      <ProjectGroup>
        <HeaderText>Professional Projects</HeaderText>
        <ExternalLinks links={professionalProjects} />
      </ProjectGroup>
      <ProjectGroup>
        <HeaderText>Video Games</HeaderText>
        <ExternalLinks links={videoGames} />
      </ProjectGroup>
    </ColGroup>
    <ColGroup>
      <ProjectGroup>
        <HeaderText>General Resources</HeaderText>
        <ExternalLinks links={generalGameResources} />
      </ProjectGroup>
      <ProjectGroup>
        <HeaderText>RPG-Specific Resources</HeaderText>
        <ExternalLinks links={specificGameResources} />
      </ProjectGroup>
    </ColGroup>
  </WelcomeWrapper>
);
